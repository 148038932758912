import { WebDFUType, WebDFU } from "dfu/index";
import { clearLog, logError, logInfo, logProgress, logWarning, setLogContext } from "./log";
import axios from "axios";

let timer: NodeJS.Timeout | null = null
let bootTimer: NodeJS.Timeout | null = null
let bootTimeNum = 0
let bootSrc: string
let soundSrc: string
let appSrc: string
let dfuId = 1155
let dfuName = 'DFU in FS Mode'
let dfuManufacturerName = 'STMicroelectronics'
let computerSystem: number
let isOkBtnFlag = false
let updateNum = 0

//获取电脑系统
function getComputerSystem() {
  var agent = navigator.userAgent.toLowerCase();
  var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
  if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
    //这是一个windows32位系统
    computerSystem = 1
  }
  if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
    //这是一个windows64位系统
    computerSystem = 1
  }
  if (isMac) {
    //这是一个mac系统
    computerSystem = 0
  }
}

function check360() {
  var result = false;
  for (var key in navigator.plugins) {
    // np-mswmp.dll只在360浏览器下存在
    if (navigator.plugins[key].filename == 'internal-nacl-plugin') {
      return !result;
    }
  }
  return result;
}


// Utils
function hex4(n: number) {
  let s = n.toString(16);

  while (s.length < 4) {
    s = "0" + s;
  }

  return s;
}

function hexAddr8(n: number) {
  let s = n.toString(16);
  while (s.length < 8) {
    s = "0" + s;
  }
  return "0x" + s;
}

function niceSize(n: number) {
  const gigabyte = 1024 * 1024 * 1024;
  const megabyte = 1024 * 1024;
  const kilobyte = 1024;
  if (n >= gigabyte) {
    return n / gigabyte + "GiB";
  } else if (n >= megabyte) {
    return n / megabyte + "MiB";
  } else if (n >= kilobyte) {
    return n / kilobyte + "KiB";
  } else {
    return n + "B";
  }
}

function formatDFUSummary(device: WebDFU) {
  const vid = hex4(device.device.vendorId);
  const pid = hex4(device.device.productId);
  const name = device.device.productName;

  let mode = "Unknown";
  if (device.currentInterfaceSettings?.alternate.interfaceProtocol == 0x01) {
    mode = "Runtime";
  } else if (device.currentInterfaceSettings?.alternate.interfaceProtocol == 0x02) {
    mode = "DFU";
  }

  const cfg = device.currentInterfaceSettings?.configuration.configurationValue;
  const intf = device.currentInterfaceSettings?.["interface"].interfaceNumber;
  const alt = device.currentInterfaceSettings?.alternate.alternateSetting;
  const serial = device.device.serialNumber;

  return `${mode}: [${vid}:${pid}] cfg=${cfg}, intf=${intf}, alt=${alt}, name="${name}" serial="${serial}"`;
}

// Current page
let webdfu: WebDFU | null = null;
const soundUpgradePage = document.querySelector('#sound-upgrade-direct') as HTMLDivElement;
const soundUpgradeConnectPage = document.querySelector('#sound-upgrade-connect') as HTMLDivElement;
const soundUpgradeConnectSuccessPage = document.querySelector('#sound-upgrade-connect-success') as HTMLDivElement;
const gotoOldUpgradeHref = document.querySelector('#sound-upgrade-bootstrap') as HTMLDivElement;
const soundUSBConnectBtn = document.querySelector('#sound-connect-ready') as HTMLButtonElement;
const connectDeviceBtn = document.querySelector('#sound-select-device') as HTMLButtonElement;
const soundUpgradeBtn = document.querySelector('#sound-start-upgrade') as HTMLButtonElement;
const soundUpgradeDeviceName = document.querySelector('#sound-connect-device-name') as HTMLDivElement;
// const connectButton = document.querySelector("#connect") as HTMLButtonElement;
const downloadButton = document.querySelector("#download") as HTMLButtonElement;
const uploadButton = document.querySelector("#upload") as HTMLButtonElement;
const statusDisplay = document.querySelector("#status") as HTMLDivElement;
const infoDisplay = document.querySelector("#usbInfo") as HTMLDivElement;
const dfuDisplay = document.querySelector("#dfuInfo") as HTMLDivElement;
const dfuHtmlContent = document.querySelector("#content-box") as HTMLDivElement;
const downloadHtmlContent = document.querySelector("#download-content") as HTMLDivElement;
const downloadChromeBtn = document.querySelector("#download-href") as HTMLButtonElement;
const linkBtn = document.querySelector("#link-btn") as HTMLDivElement;
const attachHtml = document.querySelector("#attach-r1") as HTMLDivElement;
const bootBtn = document.querySelector("#boot-btn") as HTMLDivElement;
const timbreBtn = document.querySelector("#timbre-btn") as HTMLDivElement;
const okBtn = document.querySelector("#okBtn") as HTMLButtonElement;
const stepTitleHtml = document.querySelector("#step-title") as HTMLDivElement;
const stepTextHtml = document.querySelector("#step-text") as HTMLDivElement;
const stepOneImg = document.querySelector("#step-one-gif") as HTMLImageElement;
const stepTwoImg = document.querySelector("#step-two-gif") as HTMLImageElement;
const stepThreeImg = document.querySelector("#step-three-gif") as HTMLImageElement;
const stepNextBtn = document.querySelector("#step-next") as HTMLButtonElement;
const stepNextTwoBtn = document.querySelector("#step-next-two") as HTMLButtonElement;
const timeTipsHtml = document.querySelector("#time-tips") as HTMLDivElement;
const linkTipsTwoHtml = document.querySelector("#link-tips-two") as HTMLDivElement;
const alertHtml = document.querySelector("#alert-box") as HTMLDivElement;
const duringUpgradeHtml = document.querySelector("#during-upgrade-box") as HTMLDivElement;
const upProgress = document.querySelector("#up-progress") as HTMLProgressElement;
const lastOkHtml = document.querySelector("#last-ok") as HTMLDivElement;
const upOkGoNextBtn = document.querySelector("#up-ok-go-next") as HTMLDivElement;
const questionHtml = document.querySelector("#question-box") as HTMLDivElement;
const reload1Btn = document.querySelector("#reload-btn1") as HTMLDivElement;
const upTitleHtml = document.querySelector("#up-title") as HTMLDivElement;
const upOkTextHtml = document.querySelector("#upgrade-complete-text") as HTMLDivElement;
const upOkTitleHtml = document.querySelector("#upgrade-complete-title") as HTMLDivElement;
const linkPcOkImg = document.querySelector("#link-pc-img") as HTMLImageElement;
const linkPcImg = document.querySelector("#link-img") as HTMLImageElement;
const soundImg = document.querySelector("#sound-img") as HTMLImageElement;
const progressTextHtml = document.querySelector("#progress-text") as HTMLDivElement;
const upOkImg = document.querySelector("#up-ok-img") as HTMLImageElement;
const updateOkImg = document.querySelector("#update-ok-img") as HTMLImageElement;
const stepFourImg = document.querySelector("#step-four-img") as HTMLImageElement;
const transportImg = document.querySelector("#transport-gif") as HTMLImageElement;
const linkTipsHtml = document.querySelector("#link-tips") as HTMLDivElement;
const linkTipHtml = document.querySelector("#link-tip") as HTMLDivElement;
const questionOkBtn = document.querySelector("#question-ok") as HTMLButtonElement;
const questionContentHtml = document.querySelector("#question-content") as HTMLDivElement;
const questionStickyHtml = document.querySelector("#question-sticky") as HTMLDivElement;
const videoPlayVideo = document.querySelector("#video-play") as HTMLVideoElement
const playGameImg = document.querySelector("#play-game") as HTMLImageElement

const configForm = document.querySelector("#configForm") as HTMLFormElement;

const transferSizeField = document.querySelector("#transferSize") as HTMLInputElement;
let transferSize = parseInt(transferSizeField.value);

const dfuseStartAddressField = document.querySelector("#dfuseStartAddress") as HTMLInputElement;
const dfuseUploadSizeField = document.querySelector("#dfuseUploadSize") as HTMLInputElement;

const firmwareFileField = document.querySelector("#firmwareFile") as HTMLInputElement;
let firmwareFile: ArrayBuffer | null = null;

const downloadLog = document.querySelector("#downloadLog") as HTMLDivElement;

const cardStepTwo = document.querySelector("#divStepTwo") as HTMLDivElement;
const cardStepThree = document.querySelector("#divStepThree") as HTMLDivElement;
const chkStepOneComplete = document.querySelector("#chkStepOneComplete") as HTMLInputElement;

const radioDataTypeApp = document.querySelector("#datatypeapp") as HTMLInputElement;
const radioDataTypeVoice = document.querySelector("#datatypevoice") as HTMLInputElement;

let manifestationTolerant = true;

let isDfuAppNData = false;

let directUpgradeSound = true;

/***
 * 连接usb状态下的R1
 */
function refreshDfuDevice() {
  navigator.usb.getDevices().then(devices => {
    devices.forEach(device => {
      console.log(`device id: ${device.vendorId} -- `);
      console.log(`device pName: ${device.productName} -- `);
      console.log(`device fName: ${device.manufacturerName} -- `);
      if (device.vendorId === 13502 && device.productName == "ROBKOO R1" && device.manufacturerName == "Robkoo Information & Technologies Co., Ltd.") {
        clearInterval(timer)
        linkBtn.removeEventListener('click', findDevice)
        linkBtn.style.color = '#333333'
        linkBtn.style.background = '#FFFFFF'
        linkBtn.style.fontSize = '24px'
        linkBtn.style.fontWeight = '400'
        linkBtn.innerText = `Connected：${device.productName}`
        linkTipHtml.innerHTML = `Connect R1 to your computer with the Type-C cable in package. Power on R1, and safely lay it on a flat surface. Let its LCD display face up.<br/>Click on the "Update bootloader" button below.`
        linkTipsHtml.hidden = true
        bootBtn.hidden = false
        linkPcImg.hidden = true
        linkPcOkImg.hidden = false
      } else if (device.vendorId === 1155 && device.productName == "ROBKOO R1 Firmware Update" && device.manufacturerName == "ROBKOO") {
        clearInterval(timer)
        if (directUpgradeSound) {
          soundUpgradeConnectPage.hidden = true;
          soundUpgradeConnectSuccessPage.hidden = false;
          soundUpgradeDeviceName.innerText = `Connected：${device.productName}`
          updateNum = 1
          updateTimbre()
          attachHtml.hidden = true;//隐藏旧连接页面

        } else {
          linkBtn.removeEventListener('click', findDevice)
          linkBtn.style.color = '#333333'
          linkBtn.style.background = '#FFFFFF'
          linkBtn.style.fontSize = '24px'
          linkBtn.style.fontWeight = '400'
          linkBtn.innerText = `Connected：${device.productName}`
          linkTipHtml.innerHTML = `Connect R1 to your computer with the Type-C cable in package. Power on R1, and safely lay it on a flat surface. Let its LCD display face up.<br/>Click on the "Update Sound Engine" button below, then select "ROBKOO R1 Firmware Update" from the pop up list and click "Connect".`
          linkPcImg.hidden = true
          updateNum = 1
          updateTimbre()
        }
      }
    });
  });
}

function onDisconnect(reason?: Error) {
  if (reason) {
    statusDisplay.textContent = reason.message;
    statusDisplay.hidden = false;
  }

  // connectButton.textContent = "连接设备";
  infoDisplay.textContent = "";
  dfuDisplay.textContent = "";
  // uploadButton.disabled = false;
  downloadButton.disabled = true;
  firmwareFileField.disabled = true;
}

function onUnexpectedDisconnect(event: USBConnectionEvent) {
  // console.log('进入断开',event.device)
  // console.log(webdfu)
  if (webdfu?.device) {
    if (webdfu?.device === event.device) {
      // alert('设备已断开1')
      webdfu = null;
    }
  }
}

async function connect(interfaceIndex: number) {
  if (!webdfu) {
    throw new Error();
  }

  await webdfu.connect(interfaceIndex);

  let memorySummary = "";
  if (webdfu.properties) {
    const desc = webdfu.properties;

    const info = [
      `WillDetach=${webdfu.properties.WillDetach}`,
      `ManifestationTolerant=${webdfu.properties.ManifestationTolerant}`,
      `CanUpload=${webdfu.properties.CanUpload}`,
      `CanDownload=${webdfu.properties.CanDownload}`,
      `TransferSize=${webdfu.properties.TransferSize}`,
      `DetachTimeOut=${webdfu.properties.DetachTimeOut}`,
      `Version=${hex4(webdfu.properties.DFUVersion)}`,
    ];

    // dfuDisplay.textContent += "\n" + info.join(", ");
    transferSizeField.value = webdfu.properties.TransferSize.toString();
    transferSize = webdfu.properties.TransferSize;

    if (webdfu.properties.CanDownload) {
      manifestationTolerant = webdfu.properties.ManifestationTolerant;
    }

    if (webdfu.currentInterfaceSettings?.alternate.interfaceProtocol == 0x02) {
      if (!desc.CanUpload) {
        uploadButton.disabled = false;
        dfuseUploadSizeField.disabled = true;
      }

      if (!desc.CanDownload) {
        downloadButton.disabled = true;
      }
    }

    if (webdfu.type === WebDFUType.SDFUse) {
      if (webdfu.dfuseMemoryInfo) {
        let totalSize = 0;
        for (const segment of webdfu.dfuseMemoryInfo.segments) {
          totalSize += segment.end - segment.start;
        }
        memorySummary = `Selected memory region: ${webdfu.dfuseMemoryInfo.name} (${niceSize(totalSize)})`;
        for (const segment of webdfu.dfuseMemoryInfo.segments) {
          const properties = [];
          if (segment.readable) {
            properties.push("readable");
          }
          if (segment.erasable) {
            properties.push("erasable");
          }
          if (segment.writable) {
            properties.push("writable");
          }
          let propertySummary = properties.join(", ");
          if (!propertySummary) {
            propertySummary = "inaccessible";
          }

          memorySummary += `\n${hexAddr8(segment.start)}-${hexAddr8(segment.end - 1)} (${propertySummary})`;
        }
      }
    }
  }

  // Clear logs
  // clearLog(uploadLog);
  clearLog(downloadLog);

  // Display basic USB information
  statusDisplay.textContent = "";
  // connectButton.textContent = "断开连接";
  /*
  infoDisplay.textContent =
    `Name: ${webdfu.device.productName}\n` +
    `MFG: ${webdfu.device.manufacturerName}\n` +
    `Serial: ${webdfu.device.serialNumber}\n`;
  */

  // Display basic dfu-util style info
  if (webdfu) {
    // dfuDisplay.textContent = formatDFUSummary(webdfu) + "\n" + memorySummary;
  } else {
    dfuDisplay.textContent = "未找到";
  }

  // Update buttons based on capabilities
  if (webdfu.currentInterfaceSettings?.alternate.interfaceProtocol == 0x01) {
    // Runtime
    // uploadButton.disabled = false;
    downloadButton.disabled = true;
    firmwareFileField.disabled = true;
  } else {
    // DFU
    cardStepThree.hidden = false;
    // uploadButton.disabled = false;
    downloadButton.disabled = false;
    firmwareFileField.disabled = false;
  }

  if (webdfu.type === WebDFUType.SDFUse && webdfu.dfuseMemoryInfo) {
    const dfuseFieldsDiv = document.querySelector("#dfuseFields") as HTMLDivElement;
    dfuseFieldsDiv.hidden = false;
    // dfuseFieldsDiv.hidden = true;
    dfuseStartAddressField.disabled = false;
    dfuseUploadSizeField.disabled = false;
    const segment = webdfu.getDfuseFirstWritableSegment();
    if (segment) {
      webdfu.dfuseStartAddress = segment.start;
      dfuseStartAddressField.value = "0x" + segment.start.toString(16);
      const maxReadSize = webdfu.getDfuseMaxReadSize(segment.start);
      dfuseUploadSizeField.value = maxReadSize.toString();
      dfuseUploadSizeField.max = maxReadSize.toString();
    }
  } else {
    const dfuseFieldsDiv = document.querySelector("#dfuseFields") as HTMLDivElement;
    // dfuseFieldsDiv.hidden = true;
    dfuseStartAddressField.disabled = true;
    dfuseUploadSizeField.disabled = true;
  }

}

transferSizeField.addEventListener("change", () => {
  transferSize = parseInt(transferSizeField.value);
});

dfuseStartAddressField.addEventListener("change", function (event) {
  const field = event.target as HTMLInputElement;
  const address = parseInt(field.value, 16);
  if (isNaN(address)) {
    field.setCustomValidity("Invalid hexadecimal start address");
  } else if (webdfu && webdfu.type === WebDFUType.SDFUse && webdfu?.dfuseMemoryInfo) {
    if (webdfu.getDfuseSegment(address) !== null) {
      webdfu.dfuseStartAddress = address;
      field.setCustomValidity("");
      // if (webdfu && webdfu.type === WebDFUType.SDFUse) {
      //   dfuseUploadSizeField.max = webdfu.getDfuseMaxReadSize(address).toString();
      // }
    } else {
      field.setCustomValidity("Address outside of memory map");
    }
  } else {
    field.setCustomValidity("");
  }
});

function connectDevice() {
  if (webdfu) {
    webdfu.close().catch(console.error);
    webdfu = null;

    return;
  }

  navigator.usb.getDevices().then(async devices => {
    devices.forEach(device => {
      if ((device.vendorId == "1155" &&
        device.productName == "DFU in FS Mode" &&
        device.manufacturerName == "STMicroelectronics") ||
        device.vendorId == "1155" &&
        device.productName == "ROBKOO R1 Firmware Update" &&
        device.manufacturerName == "ROBKOO") {

        webdfu = new WebDFU(
          device,
          {
            forceInterfacesName: true,
          },
          {
            info: logInfo,
            warning: logWarning,
            progress: logProgress,
          }
        );

      } else {

      }
    });

    webdfu.events.on("disconnect", onDisconnect);

    await webdfu.init();

    if (webdfu.interfaces.length == 0) {
      //statusDisplay.textContent = "The selected device does not have any USB DFU interfaces.";
      statusDisplay.textContent = "这个选中的设备不支持升级，或者需要切换到升级模式，检查第一步操作是否正确。";
      statusDisplay.hidden = false;
      return;
    }

    await connect(0);
    download().catch(console.error);
  })
    .catch((error) => {
      console.log(error);
      statusDisplay.textContent = error;
      statusDisplay.hidden = false;
    });




}


firmwareFileField.addEventListener("change", function () {
  firmwareFile = null;
  if ((firmwareFileField?.files ?? []).length > 0) {
    const file = firmwareFileField.files?.[0] as Blob;
    const reader = new FileReader();
    reader.onload = function () {
      if (reader.result instanceof ArrayBuffer) {
        firmwareFile = reader.result;
      }
    };
    reader.readAsArrayBuffer(file);
  }
});

async function download(): Promise<void> {
  // 调整Start Address如果是音色文件
  alterStartAddr();
  // console.log("dfuseStartAddressField.value:" + dfuseStartAddressField.value);
  if (!configForm.checkValidity()) {
    configForm.reportValidity();
    return;
  }

  if (webdfu && firmwareFile != null) {
    setLogContext(downloadLog);
    clearLog(downloadLog);
    downloadLog.hidden = false;

    try {
      if (await webdfu.isError()) {
        await webdfu.clearStatus();
      }
    } catch (error) {
      logWarning("Failed to clear status");
    }

    // if(updateNum === 1) webdfu.dfuseStartAddress = 137363456
    // if(updateNum === 2) webdfu.dfuseStartAddress = 169869312
    // console.log(webdfu.dfuseStartAddress)
    const process = webdfu.write(transferSize, firmwareFile, manifestationTolerant);

    // Erase
    process.events.on("erase/start", () => {
      // console.log("erase/start!");
      // logInfo("正在擦除设备存储。。。");
    });

    process.events.on("erase/process", (bytesSent, expectedSize) => {
      // logProgress(bytesSent, expectedSize);
    });

    process.events.on("erase/end", () => {
      // logInfo("擦除完成");
    });

    // Write firmware
    process.events.on("write/start", () => {
      logInfo("正在更新中。。。");
    });

    process.events.on("write/process", (bytesSent, expectedSize) => {
      // logProgress(bytesSent, expectedSize);
      if (updateNum === 1) {
        upProgress.value = 1
        upProgress.max = 100
      } else if (updateNum === 0 && (bytesSent / expectedSize > 0.85)) {

      } else {
        upProgress.value = bytesSent
        upProgress.max = expectedSize
      }
    });

    process.events.on("write/end", (bytes_sent: number) => {
      //logInfo(`Wrote ${bytes_sent} bytes`);
      // logInfo("可以放开电源按钮，设备将自动关机。");

      webdfu
        ?.getStatus()
        .then((status) => {
          // logInfo(`固件升级结果: state=${status.state}, status=${status.status}`);
          // state: 5 IDLE
          // status:0 OK
          if (status.state == 5 && status.status == 0) {
            updateNum === 0 && buryingPoint('bootupdate_done', 'Boot引导程序升级完成')
            updateNum === 1 && buryingPoint('appupdate_done', 'App音源引擎升级完成')
            updateNum === 2 && buryingPoint('soundupdate_done', '音色包升级完成')
            if (updateNum !== 1) {
              setTimeout(() => {
                lastOkHtml.hidden = false
                duringUpgradeHtml.hidden = true
                upProgress.value = 0
                upProgress.max = 0
                isOkBtnFlag = false
              }, 9000)
            }
            updateNum++
          }
        })
        .catch((error) => {
          isOkBtnFlag = false
          logError(error);
        });
    });

    process.events.on("error", () => {
      // logError(error);
      setLogContext(null);
    });

    process.events.on("end", () => {
      if (!manifestationTolerant) {
        webdfu
          ?.waitDisconnected(5000)
          .then(() => {
            onDisconnect();
            webdfu = null;
          })
          .catch(() => {
            // It didn't reset and disconnect for some reason...
            console.error("Device unexpectedly tolerated manifestation.");
          });
      }
      setTimeout(() => {
        if (updateNum === 2) {
          webdfu = null;
          axios.get(soundSrc, { responseType: 'blob' }).then(res => {
            const reader = new FileReader();
            reader.onload = function () {
              if (reader.result instanceof ArrayBuffer) {
                firmwareFile = reader.result;
              }
            };
            reader.readAsArrayBuffer(res.data);
            setTimeout(() => {
              isDfuAppNData = true
              radioDataTypeApp.checked = false
              radioDataTypeVoice.checked = true
              // firmwareFile = soundBlob
              navigator.usb.getDevices().then(devices => {
                devices.forEach(device => {
                  if (device.vendorId === dfuId && device.productName == dfuName && device.manufacturerName == dfuManufacturerName) {
                    connectDevice();
                  }
                })
              });
            }, 5000)
          })
        }
      }, 10000)
    });
  }
}


if (typeof navigator.usb === "undefined") {
  statusDisplay.textContent = "此浏览器不支持网页USB功能，请使用Chrome或Windows Edge浏览器！";
  statusDisplay.hidden = false;
  // connectButton.disabled = true;
} else {
  navigator.usb.addEventListener("disconnect", onUnexpectedDisconnect);
}


chkStepOneComplete.addEventListener("change", function () {

  if (chkStepOneComplete.checked) {
    // console.log("step one complete checked");
    cardStepTwo.hidden = false;
  } else {
    // console.log("step one complete unchecked");
    cardStepTwo.hidden = true;
  }

});

// Alter start address
function alterStartAddr() {
  // if is bootloader, don't change the start address
  if (!isDfuAppNData) {
    return;
  }
  if (radioDataTypeApp.checked) {
    // console.log("radioDataType: app");
    dfuseStartAddressField.value = "0x8300000";
    webdfu.dfuseStartAddress = 137363456
  } else if (radioDataTypeVoice.checked) {
    // console.log("radioDataType: voice");
    dfuseStartAddressField.value = "0xa200000";
    webdfu.dfuseStartAddress = 169869312
  }
}

radioDataTypeApp.addEventListener("change", function () {
  alterStartAddr();
});

radioDataTypeVoice.addEventListener("change", function () {
  alterStartAddr();
});

/**
 * 下载谷歌浏览器
 * */
downloadChromeBtn.addEventListener('click', function () {
  buryingPoint('chrome_download_click', '点击了谷歌Chrome下载')
  window.location.href = `https://www.google.com/chrome`
})
linkBtn.addEventListener('click', findDevice)
/**
 * 查找midi模式下的r1
 * */
function findDevice() {
  buryingPoint('findR1_click', '点击了查找设备')
  navigator.usb.requestDevice({ filters: [{ vendorId: 13502 }, { vendorId: 1155 }] }).then(async (selectedDevice) => {
    console.log(selectedDevice);
  })
}

/**
 * 页面加载判断是否是chrome浏览器
 * */
window.onload = function () {
  //判断是否是手机或平板
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || /(Android)/i.test(navigator.userAgent)) {
    alert('Please visit this page on computer.')
    return
  }
  if (navigator.userAgent.indexOf("Windows NT 5.0") > -1 || navigator.userAgent.indexOf("Windows 2000") > -1) {
    alert('Your operating system is too old. Please use a PC with Windows 10 or above.')
    return;
  }
  if (navigator.userAgent.indexOf("Windows NT 5.1") > -1 || navigator.userAgent.indexOf("Windows XP") > -1) {
    alert('Your operating system is too old. Please use a PC with Windows 10 or above.')
    return;
  }
  if (navigator.userAgent.indexOf("Windows NT 5.2") > -1 || navigator.userAgent.indexOf("Windows 2003") > -1) {
    alert('Your operating system is too old. Please use a PC with Windows 10 or above.')
    return;
  }
  //获取电脑系统
  getComputerSystem()
  //360浏览器
  if (check360() && (navigator.userAgent.indexOf("Safari") > -1)) {
    downloadHtmlContent.hidden = false
    return;
  }
  //QQ浏览器,UC浏览器,猎豹浏览器,欧朋浏览器,火狐浏览器,2345浏览器,搜狗浏览器
  if (navigator.userAgent.indexOf('QQBrowser') > -1 || navigator.userAgent.indexOf('UCBrowser') > -1 || navigator.userAgent.indexOf('LBBROWSER') > -1 || navigator.userAgent.indexOf('opr') > -1 || navigator.userAgent.indexOf('Firefox') > -1 || navigator.userAgent.indexOf('2345Explorer') > -1 || navigator.userAgent.indexOf('se 2.x') > -1) {
    downloadHtmlContent.hidden = false
    return;
  }
  let isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
  if (!isChrome) {
    downloadHtmlContent.hidden = false
  } else {
    // console.log(computerSystem);
    if (computerSystem) {
      questionHtml.hidden = false
      questionStickyHtml.hidden = false
    } else {
      questionStickyHtml.hidden = false
      downloadHtmlContent.hidden = true
      soundUpgradePage.hidden = false;
    }
  }
}
//首页 点击进入老的升级入口
gotoOldUpgradeHref.addEventListener('click', () => {
  soundUpgradePage.hidden = true;
  attachHtml.hidden = false;
  directUpgradeSound = false;
  timer = setInterval(() => {
    refreshDfuDevice();
  }, 1000)
})
//首页 点击进入连接设备页面
soundUSBConnectBtn.addEventListener('click', () => {
  soundUpgradePage.hidden = true;
  soundUpgradeConnectPage.hidden = false;
  timer = setInterval(() => {
    refreshDfuDevice();
  }, 1000)
})
//
connectDeviceBtn.addEventListener('click', () => {
  findDevice();
});
reload1Btn.addEventListener('click', function () {
  buryingPoint('devicedriver_done_click', '点击了驱动下载完成')
  // videoPlayVideo.pause()
  questionHtml.hidden = true
  soundUpgradePage.hidden = false;
  // attachHtml.hidden = false
  // timer = setInterval(() => {
  //   refreshDfuDevice();
  // }, 1000)
})

/**
 * 升级boot
 * */
bootBtn.addEventListener('click', function () {
  buryingPoint('bootupdate_click', '点击了升级引导程序')
  bootBtn.innerText = 'Downloading resources...'
  bootBtn.style.background = '#999999'
  axios.get(bootSrc, { responseType: 'blob' }).then(res => {
    let file = res.data
    const reader = new FileReader();
    reader.onload = function () {
      if (reader.result instanceof ArrayBuffer) {
        firmwareFile = reader.result;
        alertHtml.hidden = false
        attachHtml.hidden = true
      }
    };
    reader.readAsArrayBuffer(file);
  })
})

okBtn.addEventListener('click', debounce(() => startUp('boot'), 500))

function startUp(type: string) {
  if (type === 'boot') {
    buryingPoint('holdpower_click', '点击按住电源键不放')
  } else {
    buryingPoint('soundupdate2_click', '点击了升级音源引擎2（开始音色升级）')
  }
  if (isOkBtnFlag) return
  bootTimer = setInterval(() => {
    navigator.usb.getDevices().then(devices => {
      if (devices.length < 1) clearInterval(bootTimer)
      devices.length > 0 ? devices.forEach(device => {
        console.log(`device id: ${device.vendorId} -- ${dfuId}`);
        console.log(`device pName: ${device.productName} -- ${dfuName}`);
        console.log(`device fName: ${device.manufacturerName} -- ${dfuManufacturerName}`);
        if (device.vendorId === dfuId && device.productName == dfuName && device.manufacturerName == dfuManufacturerName) {
          isOkBtnFlag = true
          alertHtml.hidden = true
          attachHtml.hidden = true
          duringUpgradeHtml.hidden = false
          soundUpgradeConnectSuccessPage.hidden = true;
          connectDevice();
          clearInterval(bootTimer)
        } else {
          if (bootTimeNum > 2) {
            requestDevices()
          }
          bootTimeNum++
        }
      })
        :
        requestDevices()
    });
  }, 500)
}

function requestDevices() {
  clearInterval(bootTimer)
  navigator.usb.requestDevice({ filters: [{ vendorId: 1155 }] }).then(async (selectedDevice) => {
    if (webdfu) {
      webdfu.close().catch(console.error);
      webdfu = null;
      return;
    }
    isOkBtnFlag = true
    alertHtml.hidden = true
    attachHtml.hidden = true
    duringUpgradeHtml.hidden = false
    webdfu = new WebDFU(
      selectedDevice,
      {
        forceInterfacesName: true,
      },
      {
        info: logInfo,
        warning: logWarning,
        progress: logProgress,
      }
    );
    webdfu.events.on("disconnect", onDisconnect);

    await webdfu.init();
    if (webdfu.interfaces.length == 0) {
      //statusDisplay.textContent = "The selected device does not have any USB DFU interfaces.";
      statusDisplay.textContent = "这个选中的设备不支持升级，或者需要切换到升级模式，检查第一步操作是否正确。";
      statusDisplay.hidden = false;
      return;
    }

    await connect(0);
    download().catch(console.error);
  }).catch(() => {
    // linkQuestionBtn.hidden = false
  })
}

/**
 * 初次进入加载远程文件地址
 * */
axios.get('https://api.robkoo.com/rh/dfu/push').then(res => {
  if (res.data.code === 200) {
    const { boot, sound, stm } = res.data.data
    bootSrc = boot
    soundSrc = sound
    appSrc = stm
  }
}).catch(err => {
  console.log(err);
})

/**
 * boot更新完成下一步
 * */
upOkGoNextBtn.addEventListener('click', debounce(() => updateTimbre('next'), 500))

function updateTimbre(type: string | undefined) {
  if (type === 'next') buryingPoint('soundupdate1_click', '点击了升级音源引擎1（即boot升级完成）')
  isDfuAppNData = true
  radioDataTypeApp.checked = true
  dfuName = 'ROBKOO R1 Firmware Update'
  dfuManufacturerName = 'ROBKOO'
  attachHtml.hidden = false
  lastOkHtml.hidden = true
  linkPcImg.hidden = true
  linkPcOkImg.hidden = true
  soundImg.hidden = false
  bootBtn.hidden = true
  timbreBtn.hidden = false
  updateOkImg.hidden = true
  soundUpgradeBtn.style.background = '#999999'
  timbreBtn.style.background = '#999999'
  upTitleHtml.innerText = 'Updating sound bank'
  // linkTipsHtml.hidden = false
  progressTextHtml.style.fontWeight = '400'
  playGameImg.hidden = false
  linkTipsHtml.innerText = 'If your R1 is getting updated for the first time, please select "ROBKOO R1 Firmware Update" from the pop-up list and click "Connect".'
  linkTipsHtml.hidden = false
  progressTextHtml.innerHTML = `Sound bank typically takes around 10-30 minutes to update for its larger size.<br/><strong>Please stay on this page and keep your computer screen display on.</strong>`
  upOkTextHtml.innerText = 'Now you can disconnect R1 from your computer, and reboot R1.'
  upOkTitleHtml.innerText = 'Sound bank updated!'
  upOkGoNextBtn.hidden = true
  upProgress.hidden = false
  upOkImg.hidden = false
  stepFourImg.hidden = false
  transportImg.hidden = false
  axios.get(appSrc, { responseType: 'blob' }).then(res => {
    const reader = new FileReader();
    reader.onload = function () {
      if (reader.result instanceof ArrayBuffer) {
        firmwareFile = reader.result;
      }
    };
    reader.readAsArrayBuffer(res.data);
    timbreBtn.style.background = 'rgb(255, 173, 0)'
    timbreBtn.innerText = 'Update Sound Engine'
    timbreBtn.addEventListener('click', debounce(() => startUp('timbre'), 500))

    soundUpgradeBtn.style.background = 'rgb(255, 173, 0)'
    soundUpgradeBtn.addEventListener('click', debounce(() => startUp('timbre'), 500))
  })
}

stepNextBtn.addEventListener('click', function () {
  stepTitleHtml.innerText = 'Step 2'
  stepTextHtml.innerHTML = `Press the magic button on R1 twice. When R1 displays "Press&Hold Power Switch"，<br/>Click "Next" on your computer.`
  stepOneImg.hidden = true
  stepTwoImg.hidden = false
  stepThreeImg.hidden = true
  stepNextBtn.hidden = true
  stepNextTwoBtn.hidden = false
  okBtn.hidden = true
  timeTipsHtml.hidden = true
  linkTipsTwoHtml.hidden = true
})
stepNextTwoBtn.addEventListener('click', function () {
  stepTitleHtml.innerText = 'Step 3'
  stepTextHtml.innerHTML = `Please make sure you are able to keep holding the power switch of R1 without releasing it at any point. Now, start holding the power switch, and click "Holding the power switch" on your computer.<br/>If your R1 is getting updated for the first time, please select "DFU in FS Mode" from the pop-up list and click "Connect".`
  stepOneImg.hidden = true
  stepTwoImg.hidden = true
  stepThreeImg.hidden = false
  stepNextBtn.hidden = true
  stepNextTwoBtn.hidden = true
  okBtn.hidden = false
  timeTipsHtml.hidden = false
})

playGameImg.addEventListener('click', function () {
  buryingPoint('game_dino_click', '点击了小恐龙')
  window.open('https://dino.robkoo.com/')
})

//防抖
function debounce(fn, wait) {
  let timer = null;
  return (...args) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn(...args)
    }, wait)
  }
}
questionStickyHtml.addEventListener('click', function () {
  buryingPoint('Q&A_click', '点击了常见问题')
  questionContentHtml.hidden = false
})

questionOkBtn.addEventListener('click', function () {
  questionContentHtml.hidden = true
})

//事件埋点
function buryingPoint(category: string, action: string) {
  _czc.push(['_trackEvent', category, action])
}
